<template>
  <div class="tableContainer">
    <div class="topTip" v-if="isShowTopTip">*参照资料：</div>
    <div class="tableMainContainer">
      <div class="tableTitle" v-if="tableTitle" v-html="tableTitle"></div>
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        style="width: 100%"
        :header-row-style="{ height: '80px' }"
        header-row-class-name="headerRow"
        row-class-name="bodyRow"
        :cell-style="{ color: cellColor }"
        :show-summary="showSummary"
      >
        <el-table-column
          :prop="item.key"
          :label="item.label"
          align="center"
          v-for="(item, index) in headers"
          :key="index"
        >
          <template slot-scope="props">
            <!-- <div v-if="item.label == '总计'">
              {{ props.row | filterCount }}
            </div>
            <div v-if="item.label != '总计'">
              {{ props.row[item.key] }}
            </div> -->
            {{props.row[item.key]}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tip" v-if="tip">{{ tip }}</div>
  </div>
</template>
<script>
export default {
  filters: {
    filterCount(row) {
      var count = 0;
      for (const key in row) {
        if (typeof row[key] === "number" && !isNaN(row[key])) {
          count += row[key];
        }
      }
      return count;
    },
  },
  data() {
    return {};
  },
  props: {
    tableData: {
      default: () => {
        return [];
      },
      type: Array,
    },
    headers: {
      default: () => {
        return [];
      },
      type: Array,
    },
    isShowBottomCount: {
      default: false,
      type: Boolean,
    },
    cellColor: {
      type: String,
      default: "",
    },
    tip: {
      type: String,
      default: "",
    },
    tableTitle: {
      type: String,
      default: undefined,
    },
    isShowTopTip: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.tableContainer {
  // width: 1359px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto 54px;
  .topTip {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
  }
  .headerRow th:last-child,
  .bodyRow td:last-child {
    border-right: none;
  }
  .tableMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tableTitle {
      width: 100%;
      border: 2px solid #ccc;
      border-bottom: none;
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      span {
        color: #fab839;
      }
    }
    .el-table td,
    .el-table th.is-leaf,
    .el-table--border,
    .el-table--group {
      border-color: #ccc;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background-color: #ccc;
    }
    .el-table__header .cell {
      height: 80px;
      line-height: 80px;
      font-size: 25px !important;//28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .el-table__footer .el-table__cell {
      border-top: none;
    }
    .el-table__footer .el-table__cell:last-child {
      border-top: none;
      border-right: none;
    }
    .el-table__footer .cell,
    .el-table__row .cell {
      height: 100px;
      line-height: 100px;
      font-size: 23px !important;//32px;
      font-family: Source Han Sans CN;
    }
    .tableBody {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      .tableHeaders {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #ccc;
        font-size: 28px;
        font-weight: 400;
        color: #333333;
        div {
          flex: 1;
          height: 100%;
          text-align: center;
          border-right: 2px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .line {
        height: 100px;
        border-bottom: 2px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        .cell {
          border-right: 2px solid #ccc;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 23px;// 32px;
          font-weight: bold;
        }
      }
      .tableHeaders div:last-child,
      .line .cell:last-child {
        border-right: none;
      }
    }
    .tableBody .line:last-child {
      border-bottom: none;
    }
  }
  .tip {
    font-size: 20px;
    font-weight: 400;
    color: #fab839;
    margin-top: 30px;
  }
}
</style>
