<template>
  <div class="scienceContainer">
    <div v-if="schoolType == '0' || schoolType == '99'" class="primarySchool">
      <SmallTitle title="生理发育特点" />
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.低龄段（一年级~三年级）</div>
          </div>
          <div class="listValue">
            学生肌肉发育尚不完全，含水份多，肌肉纤维较细，肌腱宽而短，关节的软骨较厚，关节囊韧带薄而松弛，关节周围肌肉较细长，关节的伸展性活动范围较大，牢固性较差，容易发生脱臼。因此在体育活动和锻炼时不易进行剧烈运动，神经系统调节心脏活动的功能已发展完成，血液循环比较快，心跳较快，应防止心脏负担过重和体力活动过度。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2.高龄段（三年级~六年级）</div>
          </div>
          <div class="listValue">
            总的说来，身体发育处于相对平稳阶段，身高、坐高、体重、胸围、肩宽、骨盆宽等指标，男生均自9-10岁开始突增，女生均自8-9岁开始突增，12岁达到高峰，女生均早于男生，也是12岁达到高峰。
            <div style="margin-top: 20px"></div>
            五年级学生相对比较是属于身体发育的最高峰时期。四年级学生相对比较是属于身体发育最快的时期。
            <div style="margin-top: 20px"></div>
            骨骼成分中胶质较多，钙质较少，可塑性比较大，富弹性，坚固性较差，不容易骨折，且容易弯曲变形、脱臼和损伤，因此要特别注意身体姿势的培养和练习前的准备活动。肌肉中含水率较高，肌肉细长而且柔嫩，大肌肉群发育比小肌肉的发育要早。因此要多安排跑、跳、投等基本活动能力的练习，在注意发展大肌肉群的同时，也要注意小肌肉群的发展。
          </div>
        </div>
      </div>
      <SmallTitle title="心理发展特点" />
      <div class="intro">
        <div class="introItem" style="margin-top: 50px; font-weight: 300">
          低龄段学生心理水平还停留在不随意性和具体形象阶段；心理活动的随意性和目的性虽有所发展，但仍以不随意性为主，参加集体活动时的集体意识比较模糊，还不能清楚地意识到自己和集体的关系；意识不到班级是一个集体，意识不到班级的荣誉，还不具备自我评价能力，对活动的成功与失败不会放到心上，但喜欢听表扬的话，对批评的话语不放在心里，一会就恢复到原始状态，在学校生活和教育的条件下，有意性和抽象概括性正在迅速发展。
          <div style="margin-top: 20px"></div>
          高龄段学生心理水平感知觉的无意性和情绪性比较明显，感知事物的精确性也有所改善，从具体形象思维向抽象逻辑思维过渡，但仍然是同直接与感性经验相联系，仍然具有很大成分的具体形象性，仍习惯于模仿实际动作，自我评价意识开始形成，尊重朋友关系，愿意同水平相当者竞争，自尊心、自信心、好胜心强。
        </div>
      </div>
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.感知觉的发展特点</div>
          </div>
          <div class="listValue">
            低龄段儿童入学学习以后，有许多书写活动，手的关节肌肉运动大为增加，与此同时，手的关节肌肉感觉也发展起来，儿童虽然能够进行有目的感知觉或观察，但他们知觉也还有一定程度的混淆性、笼统性和无意性。
            <div style="margin-top: 20px"></div>
            儿童知觉的混淆性突出表现为空间方位知觉不精确，对于一些相近的图形、文字或数字，容易颠倒或认错，如：写字时把偏旁写颠倒；在写字时，有时不是由上到下，从左到右，而是由下往上或从右到左。
            <div style="margin-top: 20px"></div>
            知觉的笼统性表现在看图或识字时，往往只注意大的轮廓，容易忽略小的枝节。看图讲述或复述故事时，也常常会遗漏很多细节。
            <div style="margin-top: 20px"></div>
            知觉的无意性表现在儿童不善于独立地支配和调节自己的知觉，按一定的目的任务较系统地进行知觉的能力还较差。在观察一幅图画时，常常会把目标移到不相干的事物上。高龄段儿童感知觉属于少年阶段的特点，视觉和听觉的感受性已发展到一定水平，感知事物的目的性比童年阶段明确。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2. 注意的发展特点</div>
          </div>
          <div class="listValue">
            低龄段儿童注意范围狭窄，不善于分配自己的注意，如果要求他们同时注意几件事情，往往做不到。如：不能要求儿童边听讲边抄写，应该先让儿童听讲，等儿童听懂了，再让他们抄写。
            <div style="margin-top: 20px"></div>
            注意还不稳定、不集中，容易受不相干的事物吸引而分散注意。如：上课时注意了自己的新铅笔盒，而没有注意听教师讲解。教师讲课过程如果过于单调，或太易太难，或节奏速度不适当，都容易造成儿童注意分散。只有在教师正确的教育和帮助下，儿童的注意才能迅速发展起来。
            <div style="margin-top: 20px"></div>
            高龄段儿童集中注意能力有所发展，集中注意、专心致志的时间可达25-30分钟左右。注意分配能力也有提高，在注意腿的动作同时，还能注意到手或脚的动作，注意上下肢动作的同时，还能注意到重心的变换。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">3. 记忆的发展特点</div>
          </div>
          <div class="listValue">
            低龄段儿童记忆范围虽然不断扩大，但由于缺乏生活经验以及抽象逻辑思维发展不足，一次不易记住很多东西，有意识记忆的能力正在发展，但往往还不能很好的组织自己的记忆活动，也不能适当地运用识记的方法，意义识记的能力也正在发展，对于已经理解的教材，能够通过逻辑加工来进行识记。如：按意义联系来识记课文，按偏旁部首归类来识记生字等。
            <div style="margin-top: 20px"></div>
            形象记忆在一年级儿童记忆中还占重要地位。因此，教学中仍然要多运用直观方法来巩固所学的知识。
            <div style="margin-top: 20px"></div>
            高龄段儿童集中注意能力有所发展，集中注意、专心致志的时间可达25-高龄段儿童有意记忆在不断发展，开始由教师布置任务的记忆过渡到自觉的记忆，有意记忆逐步发展并占主导地位，抽象记忆有所发展，但具体形象记忆的作用仍非常明显。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">4.思维的发展特点</div>
          </div>
          <div class="listValue">
            虽然已经具有一定的抽象概括能力，并且掌握了一些概念，能够初步进行判断和推理，但思维水平总的来说仍是比较低的，仍有很大的具体形象性，思维过程往往依靠具体的表象，不易理解较抽象或与他的经验很少联系的教材，思维还带有很大的依赖性和模仿性，不善于使自己的思维活动服从于一定的目的任务，在思考问题时往往被一些不相干的事物所吸引，以致离开原有的目的任务。
            <div style="margin-top: 20px"></div>
            高龄段儿童已从具体形象思维向抽象逻辑思维过渡，但仍然是同直接与感性经验相联系，仍然具有很大成分的具体形象性，仍习惯于模仿实际动作。因此，需加强启发式教学，发展学生比较、分析，综合思维的能力，学生逐步学会分出概念中本质与非本质，主要与次要的内容，学会掌握初步的科学定义，学会独立进行逻辑论证，但他们的思维活动仍然具有很大成分的具体形象色彩。在想象方面，学生想象的有意性迅速增长并逐渐符合客观现实，同时创造性成分日益增多。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">5. 自我意识的发展特点</div>
          </div>
          <div class="listValue">
            在自我意识方面，幼儿期儿童的心理活动和行为更多地受情境因素的支配，变化较大，因此，还不能形成真正稳定的个性。
            <div style="margin-top: 20px"></div>
            进入小学后，特别是通过低年级阶段的学习适应性锻炼，他们逐步把握个人与他人的关系，形成集体意识，学生的自我意识、道德观念和道德行为在这过程中逐渐发展起来。
            <div style="margin-top: 20px"></div>
            小学高年级学生的自我意识逐步深刻，渐渐摆脱对外部控制的依赖，逐渐形成了内化的行为准则作为监督、调节、控制自己的行为的依据，而且开始从对自己表面行为的认识、评价转向对自己内部品质的更深入的评价
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">6.人际关系的特点</div>
          </div>
          <div class="listValue">
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（1）与同伴交往的特点</div>
              </div>
              <div class="listValue">
                小学生的同伴关系有一个发展的过程，这一过程与小学生认识过程的发展相适应，也与小学生在班集体中的集体活动经验有关。
                <div style="margin-top: 20px"></div>
                其一，他们倾向于选择与自己的兴趣、习惯、性格和经历相和谐的人做朋友；其二，他们倾向于选择品行得到社会赞赏的人做朋友，如挑选学习成绩比自己好或能力比自己强的人做朋友。小学生的同伴关系还具有一定的性别特点。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（2）与父母交往的特点</div>
              </div>
              <div class="listValue">
                虽然小学生与同伴的交往明显增多，但与父母仍保持着亲密的关系，对父母仍怀有深厚的感情。因此，小学生与父母的关系在其发展上仍起着重要作用。
                <div style="margin-top: 20px"></div>
                一般来说，在家庭中，父母主要通过以下途径对小学生施加影响：第一，教导，即父母通过言传身教，直接向小学生传授各种社会经验和行为准则；第二，强化，即父母采取奖惩的方式强化小学生的行为准则，并巩固这些行为准则的地位；第三，榜样，即父母往往是小学生最早开始模仿的对象，他们效仿父母，学习父母的行为方式；第四，慰藉，即小学生对父母形成的依恋感使他们易于向父母倾诉不安和烦恼，以得到父母的安慰和帮助。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（3）与教师交往的特点</div>
              </div>
              <div class="listValue">
                小学生与教师的关系是其人际关系中的一种重要关系。与幼儿园的教师相比，小学教师更为严格，既引导学生学习，掌握各种科学知识与社会技能，又监督和评价学生的作业、品行。
                <div style="margin-top: 20px"></div>
                与中学教师相比，小学教师的关心和帮助更加具体而细致，也更具有权威性。几乎每一个儿童在刚跨进小学校门时都对教师充满了崇拜和敬畏，教师的要求甚至比家长的话更有权威。
                <div style="margin-top: 20px"></div>
                对小学低年级学生来说，教师的话是无可置疑的，这种绝对服从心理有助于他们很快学习、掌握学校生活的基本要求。但是，随着年龄增长，小学生的独立性和评价能力也随之增长起来。
                <div style="margin-top: 20px"></div>
                从三年级开始，小学生的道德判断进入可逆阶段，学生不再无条件地服从、信任教师了。他们对教师的态度开始发生变化，开始对教师作出评价，对不同的教师表现出不同的喜好
              </div>
            </div>
          </div>
        </div>
      </div>
      <SmallTitle title="小学儿童常见的心理问题" />
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.入学适应问题</div>
          </div>
          <div class="listValue">
            儿童从幼儿园进入小学，从校园的自然环境到老师、同学的人际关系环境都是陌生的，还有学习的
            压力校规校纪的约束等，都构成了新入学儿童心理适应的严峻挑战。部分儿童会出现适应不良现象，大多表现在：产生情绪障碍，如焦虑、不安、抑郁、害怕等；注意力不集中，然后对学习失去兴趣；不能约束自己，总是违反纪律等。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2.逃学与厌学问题</div>
          </div>
          <div class="listValue">
            据调查，有厌学情绪的小学生占总数的5%-10%。这些小学生主要是对学习缺乏兴趣，学习比较吃力，导致长期落后，又缺乏赶上去的勇气好毅力，老师没能及时地给予鼓励，挫伤了学习的积极性。因此他们或是在课堂上东张西望、魂不守舍；或者在下面偷偷看动画书或玩玩具，或是打瞌睡，有的干脆逃学、旷课。还有一些优等生，由于心理承受较差，一旦别人超过自己或目标未达到，就容易产生厌学情绪。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">4.过度依赖问题</div>
          </div>
          <div class="listValue">
            现在大多数家庭都是独生子女，父母对他们百般呵护，甚至溺爱，造成孩子自立能力差。遇到困难时一次又一次向父母求助，得到帮助时，就生成了依赖感。随着这样的恶性循环，小学生就像温室里的花，经不起风吹雨打，意志力非常薄弱。
          </div>
        </div>
      </div>
    </div>
    <div class="middleSchool" v-if="schoolType == '1' || schoolType == '2' || schoolType == '99'">
      <div class="intro">
        <div class="introItem" style="margin-top: 50px; font-weight: 300">
          中学生正处于青年初期阶段，在此期间，生理和心理发展都已相当成熟，是学生准备走向独立生活的时期。应该说，中学生已经是“准成人”了，
          人生的中学时期，曾被人喻为多事的季节，心理学上谓之
          “心理断乳期”，其年龄范围大体为13至18岁，属于少年期和青年早期，俗称为青春期。
          <div style="margin-top: 20px"></div>
          中学生心理发展的显著特点是：生活和社会体验开始占主导地位；急剧而又呈弥散性的身体和激素变化对他们的心理产生重要的影响，他们渴望自己能被他人像成人一样对待；自我形象在波动摇晃；心理社会思潮常常像“巨浪”般涌现；身体发展的不一致性，会因为希望自己被同龄伙伴、异性对象和家庭成员认为正常、为人吸引的欲望而变得更加突出。
        </div>
      </div>
      <SmallTitle title="生理发育特点" />
      <div class="intro">
        <div class="introItem" style="margin-top: 50px; font-weight: 300">
          身高的增长是初中学生身体发育的早期表现之一。人的身高增长有两个快速期，出生后的一年内是第一个加速期，身高几乎增长一半，然后发展速度减慢，到初中阶段发育进入第二个快速期，性机能发育是影响青少年心理发展的突出生理因素。
          人生的中学时期，曾被人喻为多事的季节，心理学上谓之
          “心理断乳期”，其年龄范围大体为13至18岁，属于少年期和青年早期，俗称为青春期。
          <div style="margin-top: 20px"></div>
          少年随着身体的猛长，性器官和性腺迅速发展，性机能成熟，开始进入青春发育期。性成熟开始的标志是：性腺机能增强，第一性征发育迅速，第二性征开始出现。第一性征是指生殖器官的发育。第二性征即副性征，男性的声音变粗，甲状软骨开始增大，出现胡须、遗精现象等;女性声音变高，乳房隆起，月经初潮等。生理学的研究材料指出：这个时期由于脑垂体前叶分泌的激素对初中学生身体各系统的刺激，使得他们的身高、体重迅速增长，并且与甲状腺一起加强着机体内的新陈代谢过程。
          <div style="margin-top: 20px"></div>
          一般地说，女生的性成熟期比男生早一两年，女生从11、12岁开始，男生则从13、14岁开始进入性成熟期。
          <div style="margin-top: 20px"></div>
          人脑，特别是神经系统是心理发展的直接物质基础。进入初中阶段的学生，脑的重量和结构已接近成人。研究表明，人脑平均重量的发展，到12岁时已达到1
          400克；到13或14岁时，脑的组织结构基本发育成熟，如皮质的沟回已经分明。两半球的联络纤维大量增加，神经髓鞘化已经完成。大脑的机能显著发展并趋于成熟。如神经元的分化机能基本达到成人的水平，第二信号系统的作用有了显著的提高。但初中学生的兴奋过程一般比抑制过程强，兴奋与抑制的相互转换也较快。因此，他们在各种活动中，自觉地控制自己的情绪和调节自己行为的能力不强。
        </div>
      </div>
      <SmallTitle title="心理发展的主要特点" />
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.认知活动的特点</div>
          </div>
          <div class="listValue">
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">(1)观察积极主动但精确性不高</div>
              </div>
              <div class="listValue">
                初中学生一般能够根据教学的要求去观察某种对象和现象，并且能够较稳定地、长时间地去进行有意识的观察，如观察物理、化学的实验和生理卫生的挂图、模型等。学生能自觉地按照教师的要求去进行观察，不过他们有时还会出现观察不稳定或以情绪为转移的现象。
                <div style="margin-top: 20px"></div>
                观察的精确性日益提高，能抓住事物的主要特点，把它和相近的事物区分开来。他们不仅能感知事物的外部属性，同时能抓住事物的主要特征和本质属性进行全面、深刻的观察；并且在这个基础上了解和掌握事物各部分之间的相互关系。比如，他们能掌握一般事物三度空间(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">(2)意义识记开始占优势</div>
              </div>
              <div class="listValue">
                初中学生的无意识记表现得比较明显，他们对自己感到有兴趣的、新颖的、直观的材料记得较好，而对一些比较抽象的材料，如系统的理论、公式、定理、法则等识记得就比较差。在教学的影响下，学生逐步学会使自己的记忆服从于识记的任务和教材的性质，因而有意识记日益占优势。
                <div style="margin-top: 20px"></div>
                意义识记是一种理解性的识记。在初中教学过程中，教师对学生的意义识记提出了更高的要求，要求他们对识记的材料分析综合，从而找出各部分内容之间的内在联系。因此，初中学生逐渐能够通过理解来掌握课文内容，这就促使了他们意义识记的迅速发展。(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
                <div style="margin-top: 20px"></div>
                同时，初中学生在学习过程中词的抽象识记在具体形象识记的基础上逐步地形成和发展起来。初中学生开始能够用抽象公式、定理来理解具体事物，这就使他们的识记向理解水平迈出了新的一步。但是，我们不能对初中学生的抽象识记估计过高，他们对抽象材料的识记只是初步的。
                <div style="margin-top: 20px"></div>
                根据心理学实验证明，初中学生对具体材料识记的指标仍然高于对抽象材料识记的指标。因此，直观教学在初中阶段仍然占有重要的地位。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">(3)抽象思维占据主导地位</div>
              </div>
              <div class="listValue">
                所谓抽象思维就是正确地掌握概念并运用概念组成恰当的判断，进行合乎逻辑推理的一种思维。在初中阶段，随着学生知识经验的不断增长，抽象思维开始占主要地位，但是初中学生的抽象思维在很大程度上还属于“经验型”的，即在他们的抽象思维中具体形象的成分仍然起着重要的作用。
                <div style="margin-top: 20px"></div>
                中学生的理解随着知识经验的增加和抽象思维的发展而有明显的提高。所谓理解就是懂得事物的本质和事物间的联系，它是在已有的知识经验的基础上去了解和掌握新的知识，并把新的知识纳入已有的认知结构之中，知道是什么、为什么、怎么样，就是说不仅知其然，而且也知其所以然。众所周知，理解是掌握知识的中心环节。(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
                <div style="margin-top: 20px"></div>
                学生对事物的理解，最初只停留在表面现象和具体情节的叙述上，后来逐渐摆脱事物的具体情节去理解事物的本质。他们对事物因果关系的理解，对作品中人物的理解，对矛盾关系的理解等方面，其理解力就表现出这种由低级向高级发展的趋势。比如，对作品中人物的理解，开始多注意人物的活动情节，而不能独立揭示人物的行为动机，初中学生在教师指导下，逐步学会透过人物的具体活动，分析人物的行为动机。
                <div style="margin-top: 20px"></div>
                初中学生由于初步掌握了比较系统的科学知识、能够理解自然现象和社会现象中的一些比较复杂的关系和联系，所以他们开始用批判的观点看待周围的事物，常常不满足于教师在课堂上的讲解和书本中关于事物现象的解释，喜欢独立地寻求事物现象的原因和规律，为此，他们喜欢争论、探讨、追问。他们不仅能批判地对待别人和书本上的观点，而且开始比较自觉地对待自己的认识和见解，有意识地检查和论证自己的认识和见解。
                <div style="margin-top: 20px"></div>
                但在初中阶段，他们这种思维的独立性和批判性还很不成熟，其不足在于容易产生片面性和表面性。他们有时毫无根据地争论，在论证不足的情况下还固执己见，有时他们考虑问题容易犯绝对化的错误，对人对事容易有偏激情绪。他们思维发展上的这些不足，与他们的知识经验不足、辩证思维还不发展密切相关。因此，教师一方面要鼓励他们敢于创新；另一方面又要善于引导，耐心教育，克服他们独立思考中可能发生的偏向。
              </div>
            </div>
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2.富有热情，自制力差，容易冲动</div>
          </div>
          <div class="listValue">
            初中学生情绪激荡，容易动感情，也容易激怒，这种冲动性与他们的生理发育，特别是神经活动的兴奋过程强，抑制过程薄弱有一定关系。他们常常因为一件小事而狂笑，也会因为某一件小事而颓丧，初中学生的这种情绪外露是很明显的。他们对人对事充满热情，很容易被那些可歌可泣的英雄事迹所感染。
            <div style="margin-top: 20px"></div>
            他们具有活泼愉快的心境，对周围现实生活中发生的各种事情都十分关注，对国内外政治生活中的重大事件也很关心。这时，他们的社会情操和爱国主义感、集体主义感、同志友谊感、责任感、义务感等，也已经初步形成。
            <div style="margin-top: 20px"></div>
            此外，理智感和美感都有所发展。初中学生开始根据自己的兴趣爱好和道德标准来选择朋友，他们非常重视友谊和社会交往，有时甚至为此不惜一切代价，但是容易把友谊局限于狭隘的范围，甚至因此而脱离集体，出现一些不健康的情绪和行为，例如，搞小团体、互相庇护等。因此，教师对初中学生要及时进行教育，引导他们形成正确的集体舆论。
            <div style="margin-top: 20px"></div>
            初中学生在各种活动中表现出来的意志品质，例如自觉性、果断性和坚持性都有明显的提高。他们特别崇拜那些意志坚强的人，往往也下决心试图培养自己良好的意志品质，但是，他们的意志较薄弱，特别是遇到困难或者遭到失败的时候，往往表现得缺乏毅力，有时把即将完成的工作放弃了，甚至有的人把已经完成的部分也完全毁掉。
            <div style="margin-top: 20px"></div>
            他们意志品质的理解也不全面，表现为蛮干，甚至作出损害自己健康的事情，例如，为了锻炼自己大胆和勇敢，在汽车行驶中爬车，站在悬崖上探身采花；为了锻炼自己的坚强意志，冬天故意穿很少的衣服在雪地里走等等。因此，教师必须引导他们正确地估计自己的力量和锻炼自己的意志。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">3.自我意识增强，“成人感”突出</div>
          </div>
          <div class="listValue">
            (1)开始对人的内部世界、心理品质发生兴趣，开始要求了解别人和自己的个性特点。
            <div style="margin-top: 20px"></div>
            (2)开始能自觉地评价自己，独立地选择学习的榜样。他们认识和评价自己的能力是从认识和评价别人过渡来的。许多研究表明，初中学生评价别人比评价自己具有更大的独立性，内容也更丰富更具体。
            <div style="margin-top: 20px"></div>
            (3)开始把自己看作是“成年人”，对自己的要求有了更高的自觉性。初中学生开始意识到自己是个“成年人”，“成人感”很突出。为了证明自己是“成年人”，他们在各种场合中都想表现自己，力争参加成年人的各种工作和实际活动。这种愿望提高了他们的责任感，发挥了他们的创造性和主动性。
            <div style="margin-top: 20px"></div>
            另外，他们最怕别人把自己看成是“弱者”，为了表现自己是强者，他们有时容易出现一些冒险行为。
            <div style="margin-top: 20px"></div>
            因此，教师必须掌握他们的这些特点，应当关心和教育他们，对他们的评价要做到恰如其分，耐心地教育他们学会客观地、全面地看待别人和自己，学会辩证地分析问题，逐步引导他们自觉地主动地进行自我评价和自我教育。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">4.兴趣开始分化，理想尚不够稳定</div>
          </div>
          <div class="listValue">
            初中学生对于各门学科开始表现出不同的兴趣爱好，例如，特别喜欢某些学科，或者不喜欢某些学科；特别喜爱某些活动，或者不喜欢某些活动。
            <div style="margin-top: 20px"></div>
            根据调查研究发现，初中学生特别喜爱某一学科的原因，首先与教师的教学质量有关；其次与学生的学习成绩有关。这说明由于获得好成绩而产生的满意的积极情感有助于发展和巩固他们的学习兴趣，但是教师必须注意到，中学教育还是基础教育，要注意防止学生学习兴趣的片面性。
            <div style="margin-top: 20px"></div>
            初中学生虽然产生了各种各样的理想，但他们的理想常体现在某个具体形象上，理想的人物有时是直接接触到的，被他们所敬仰的人，有时是小说中的英雄人物。初中学生的理想还不稳定，容易受外界影响，情境性很大。例如，他们看了《白求恩大夫》的电影就想当医生，看了侦探小说又想当公安战士，他们什么事都想做，见异思迁，所谓“少年多志”就是这个年龄阶段学生理想的主要特点。
          </div>
        </div>
      </div>
      <SmallTitle title="中学生心理发展的矛盾" />
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.渴望独立自主与成人管教的矛盾</div>
          </div>
          <div class="listValue">
            初中学生随着思维的独立性、批判性及自我意识的发展，他们的独立自主的要求突出表现出来，对父母的细致的关心和絮絮叨叨的叮嘱很反感；喜欢独立活动，独立完成任务。但是他们由于知识、能力有限，认识问题比较片面，在完成活动任务的过程中常出现困难和挫折，还需要依靠家长和教师的帮助。这既意味着初中学生要独立成长，又意味着他们还有很多困难需要靠成人帮助。
            <div style="margin-top: 20px"></div>
            针对这种心理矛盾，家长、教师应当理解、尊重初中学生独立性、自主性的要求，在安排活动时，一方面要尊重信任他们，为他们积极创造条件，发挥他们的独立性，培养他们独立工作的能力，不要包办代替。否则，就会使他们的独立性发展受到阻碍，自尊心受到损伤，就可能对教师、家长的正确管教产生厌恶甚至对抗情绪；另一方面又必须给予严格的要求和耐心细致的指导，但是对他们应当注意启发引导多于管教，从而使他们的独立性、自尊心沿着健康的方向发展。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2.个人活动能量增强与认识水平低的矛盾</div>
          </div>
          <div class="listValue">
            初中学生生长发育迅速，精力旺盛，个人活动能量增强，活动需要强烈，热衷于社会交往。但他们的认识水平有限，活动的目的性、自觉性不高，常常出现一些盲目的行为。他们遇事缺乏深思熟虑，容易感情用事，所做的许多错事，都是由于自己认识水平低，辨别是非的能力差造成的。
            <div style="margin-top: 20px"></div>
            他们有时还把幻想作为强烈的活动动机，干出一些令人可笑的事。他们的自我控制能力较差，往往容易出现过失行为，针对这种情况，教师一方面积极开展学校内的集体活动，组织社会公益劳动，使学生的活动能量得到充分发挥，活动的需要得到满足。另一方面，加强教育引导，提高认识，增强他们活动的目的性和自我控制能力。
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">3.个人活动的欲望与集体行为准则的矛盾</div>
          </div>
          <div class="listValue">
            初中学生一般活泼好动，充满热情，个人活动的欲望较强，情绪具有较大的冲动性。他们不善于控制自己情绪，有时容易出现违背集体行为准则的言行。因此，教师一方面要加强道德、法制、纪律教育，提高他们的认识水平，增强他们的自制力；另一方面，当学生由于不合理的个人活动欲望而违犯了集体纪律时，教师应采取“冷处理”的方式进行疏导，否则将激化矛盾，产生对立情绪，逃学甚至离家出走等行为。
            <div style="margin-top: 20px"></div>
            初中学生的独立性、“成人感”在迅速发展，他们越来越强烈地要求参加到社会性的活动中去，处处想独立自主。随着他们年龄的增长，教师、家长在他们的心目中已不再是不容怀疑的绝对权威，他们与家庭间的感情联系也会变得渐渐淡薄起来。对初中学生心理发展影响的主要因素则是他们所处的社会环境，许多研究证明：电影、电视、录像等对不同年龄阶段学生的心理发展均能发生影响，但其中对初中学生的影响最为深刻。
          </div>
        </div>
      </div>
      <SmallTitle title="中学生常见的心理问题" />
      <div class="listContainer">
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">1.学习问题</div>
          </div>
          <div class="listValue">
            （1）学生学习的心理压力越来越大，造成精神上的萎靡不振，从而导致食欲不振、失眠、神经衰弱、记忆效果下降、思维迟缓等。
            <div style="margin-top: 20px"></div>
            （2）厌学是目前学习活动中比较突出的问题，不仅是学习成绩差的同学不愿意学习，一些成绩较好的同学亦出现厌学情绪。"国家教育委员会的一位负责同志在一次会议上说，初中生厌学的有30%，有的县甚至高达60%"。可见，厌学是一个普遍问题。
            <div style="margin-top: 20px"></div>
            （3）考试焦虑，特别是遇到较为重要的考试时焦虑更为严重，甚至出现焦虑泛化现象。一种毕业后无出路的忧愁和恐慌感控制着他们，这一种情况反映在学习上，就是感到学习的沉重，讨厌学习，对考试感到焦虑。
          </div>
        </div>

        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">2.人际关系问题</div>
          </div>

          <div class="listValue">
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（1）与教师的关系问题</div>
              </div>
              <div class="listValue">
                以往其主要问题是教师对学生的不理解、不信任而使学生产生的对抗心理，以及教师的认知偏差等情况给学生造成的压抑心理，攻击行为等问题。
                <div style="margin-top: 20px"></div>
                初中时期，教师仍然是学生的理想目标、公正代表，他们希望得到教师的关心理解与爱。如果教师缺乏理解、耐心与爱心，不能以热情的态度给予指导帮助，反而横加指责，学生则会失望。(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（2）同学间的关系问题</div>
              </div>
              <div class="listValue">
                人和人的交往是一个双方相互接触的过程，要达到交往和谐，要能够正确认识自我，而且还要能够认识和了解他人。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（3）与父母的关系问题</div>
              </div>
              <div class="listValue">
                民主型的和睦良好的家庭给中学生一个温暖的归属港湾，专制式的家庭中父母与其子女之间不能进行正常的沟通，造成儿童孤僻、专横性格。家庭的种种伤痕，会给初中生造成不同程度的心理伤害。

                <div style="margin-top: 20px"></div>
                有研究认为，父母不和比父母一方死亡，会给儿童在心理问题上造成更大伤害，因为他们在父母那里看到了人际关系的恶劣性。父母不和对中学生的心理影响是多方面的，"有被抛弃感和愤怒感；并有可能变得抑郁，敌对，富于破坏性，……还常常使得他们对学校作业和社会生活不感兴趣"。他们会认为是自己的父母给自己带来并制造了痛苦，憎恨父母家庭，因为家庭是不幸的一切所在。(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
              </div>
            </div>
          </div>
        </div>
        <div class="listItem">
          <div class="listTitle">
            <div class="icon"></div>
            <div class="title">3.青春期心理问题</div>
          </div>

          <div class="listValue">
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（1）青春期闭锁心理</div>
              </div>
              <div class="listValue">
                其主要表现是趋于关闭封锁的外在表现和日益丰富、复杂的内心活动并存于同一个体，可以说封闭心理是青春期心理的一个普遍存在而又特殊的标志。因而闭锁心理问题主要是指处于消极情况下的心理而言的。初中生若在消极情绪控制之下，封闭与外界的任何心理交流，将是一个值得注意的征兆。
                <div style="margin-top: 20px"></div>
                一些中学生跳楼、扼腕自杀，其最主要的原因也就是这点。如果能给这些同学们再一次生存的机会，让他们学会怎么样将自己的心思说给外界人听，那他们就会活着，外界的人会给他们很好的建议，让他们有勇气来面对问题，也不会走上无希望的路。(长、宽、高)的相互关系和图形的透视原理等，远距离的空间知觉即宏观知觉也逐渐形成起来。
              </div>
            </div>
            <div class="listItem" style="padding-left: 50px">
              <div class="listTitle">
                <div class="title">（2）早恋</div>
              </div>
              <div class="listValue">
                初中生尚未成年，我们把初中生这种未成人时的恋爱称为早恋。初中时代，正值青春发育期，而这一时期最突出的矛盾之一是性发育迅速成熟与性心理相对幼稚的矛盾。
                <div style="margin-top: 20px"></div>
                初中生由于受认识能力和个性发展的限制，特别是在教育引导不及时不得力的情况下，使得中学生的性心理的发展表现出相对的幼稚性，所以自认为认真的、朦胧状态下的恋爱出现了，尽管这种恋爱带有好奇和模仿成份，但却是一个令人十分关注的问题。早恋虽会给一些人留下美丽的回忆，可也会逝去不少美好的青春时光。到最后得不偿失，痛苦不堪，也不是不会发生的。
                <div style="margin-top: 20px"></div>
                除了以类型的心理问题外，还有进食问题、失眠、吸烟、喝酒、滥用药物等问题都应该引起学校及广大家长的高度关注。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SmallTitle from './smallTitle.vue'
export default {
  props: ['schoolType'],
  components: {
    SmallTitle
  }
}
</script>
