<template>
  <div class="introContainer">
    <div class="bigTitle introT"></div>
    <SamllTitle title="测评简介" />
    <div class="intro">
      <div class="introItem">
        儿少心理健康量表(mental health scale forchild and
        adolescent，MHS-CA)由程灶火等于2001年编制，于2004年完成信效度研究和全国常模建立。该量表从24个方面去观察儿童和青少年的心理健康状况，既能反映儿童和青少年的心理过程，也反映儿童和青少年的心理特征。该量表的信效度较好，符合统计学标准。
      </div>
      <div class="introItem">
        儿少心理健康量表属于评定量表，小学低年级（一年级～三年级）由父母与孩子共同讨论填写，小学高年级（四年级～六年级）、中学生可以自己填写。评定时限为“目前”或“最近一个月”的实际情况。
      </div>
    </div>
    <SamllTitle title="量表内容" />
    <div class="intro">
      <div class="introItem" style="margin-top: 70px">
        量表包括24个反映儿童心理过程和特征的条目，这些条目可细分为5个维度（分量表）。
      </div>
    </div>
    <div class="detailContainner">
      <div class="detailItem" v-for="(item, index) in detailList" :key="index">
        <div class="itemTitle">
          <img :src="item.title" alt="" />
        </div>
        <div class="itemValue">
          {{ item.content }}
        </div>
      </div>
    </div>
    <SamllTitle title="测量学指标" style="margin-top: 50px" />
    <div class="detailContainner SurveyingContainner">
      <div
        class="detailItem"
        v-for="(item, index) in SurveyingList"
        :key="index"
      >
        <div class="itemTitle">
          <img :src="item.title" alt="" />
        </div>
        <div class="itemValue">
          {{ item.content }}
          <div style="width: 100%; text-align: center">
            <img
              v-if="item.img"
              style="width: 100%; height: auto; margin: 44px 0"
              :src="item.img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <SamllTitle title="参考文献" style="margin-top: 55px" />
    <div class="literatureContainer">
      <div
        class="literatureItem"
        v-for="(item, index) in literature"
        :key="index"
      >
        <div class="literatureTitle">
          <div class="icon"></div>
        </div>
        <div class="literatureValue">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SamllTitle from "./smallTitle.vue";
export default {
  components: {
    SamllTitle,
  },
  data() {
    return {
      detailList: [
        {
          title: require("../assets/images/caseReport/detail1.png"),
          content: "包括感知觉、注意、记忆、智力、学习与工作5个条目。",
        },
        {
          title: require("../assets/images/caseReport/detail2.png"),
          content:
            "包括思维过程、思维内容、思维自主性、语言表达、语言理解5个条目。",
        },
        {
          title: require("../assets/images/caseReport/detail3.png"),
          content: "包括焦虑体验、愉快体验、情绪反应3个条目。",
        },
        {
          title: require("../assets/images/caseReport/detail4.png"),
          content: "包括行为、活动、兴趣、人际交往、健康关注5个条目。",
        },
        {
          title: require("../assets/images/caseReport/detail5.png"),
          content:
            "包括自信与自尊、安全与信任、责任感、活泼性、仁慈心、需要满足6个条目。",
        },
      ],
      SurveyingList: [
        {
          title: require("../assets/images/classReport/surveying1.png"),
          content:
            "量表的Crobach α信度系数为0.847，分半信度为0.800，重测信度为0.713，评定者信度为0.847。",
        },
        {
          title: require("../assets/images/classReport/surveying2.png"),
          content:
            "量表的5个维度有中度相关，相关值在0.38～0.58之间，各维度分与总分的相关值在0. 502～0.745之间，即各分量表相对独立又有一定的相关，分量表分与总分有较好的相关。",
          img: require("../assets/images/classReport/surveying2_table.png"),
        },
        {
          title: require("../assets/images/classReport/surveying3.png"),
          content:
            "正常样本与异常样本在量表总分和各维度上得分差异均有显著性（P<0.001），常模样本的得分显著高于异常样本的得分。",
        },
        {
          title: require("../assets/images/classReport/surveying4.png"),
          content:
            "儿少心理健康量表总分与Achenbach儿童行为量表（CBCL）的4种能力得分呈正相关，与11种问题得分呈负相关。两个量表之间有一定的相关，性质相近的分量表相关高一些，性质不同的分量表相关低一些。",
        },
      ],
      literature: [
        "程灶火,袁国桢,杨碧秀,林燕,方有荣,赵勇,张轩,杨英,苏艳华,欧阳旭伟.儿童青少年心理健康量表的编制和信效度检验[J].中国心理卫生杂志,2006(01):15-18.",
        "袁国桢,程灶火,杨碧秀.不同地区中小学生心理健康状况的调查[J].中国临床康复,2006(34):50-52.",
        "杨碧秀,程灶火.不同年级的中小学生心理问题研究[J].中国健康心理学杂志,2006(03):270-272.",
        "杨碧秀,程灶火.不同年级的中小学生心理问题研究[J].中国健康心理学杂志,2006(03):270-272.",
      ],
    };
  },
};
</script>
<style lang="scss">
.bigTitle {
  width: 620px;
  height: 56px;
  margin: 64px auto 101px;
}
.scienceT {
  background: url("../assets/images/classReport/scienceT.png") no-repeat center;
  background-size: 100% 100%;
}
.noticeT {
  background: url("../assets/images/classReport/noticeT.png") no-repeat center;
  background-size: 100% 100%;
}
.introT {
  background: url("../assets/images/caseReport/intro_bigTitle.png") no-repeat
    center;
  background-size: 100% 100%;
}
.summaryT {
  background: url("../assets/images/classReport/summaryT.png") no-repeat center;
  background-size: 100% 100%;
}
.partTest {
  background: url("../assets/images/classReport/partTest.png") no-repeat center;
  background-size: 100% 100%;
  margin-top: 88px;
}
.resultT {
  background: url("../assets/images/classReport/resultT.png") no-repeat center;
  background-size: 100% 100%;
  margin-top: 125px;
}
.intro {
  width: 100%;
  padding: 0 143px;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  line-height: 56px;
  .introItem {
    margin-bottom: 58px;
  }
}
</style>
