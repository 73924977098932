import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

/**
 * @param  ele          要生成 pdf 的DOM元素（容器）
 * @param  padfName     PDF文件生成后的文件名字
 * */

function downloadPDF(ele, pdfName) {

    let eleW = ele.offsetWidth; // 获得该容器的宽
    let eleH = ele.offsetHeight; // 获得该容器的高


    let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
    let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

    var canvas = document.createElement("canvas");
    var abs = 0;

    let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
    let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

    if (win_out > win_in) {
        // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
        abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
    }

    canvas.width = eleW * 2; // 将画布宽&&高放大两倍
    canvas.height = eleH * 2;




    var context = canvas.getContext("2d");

    context.scale(2, 2);

    context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
    // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
    // translate的时候，要把这个差值去掉

    // html2canvas(element).then( (canvas)=>{ //报错
    // html2canvas(element[0]).then( (canvas)=>{
    html2canvas(ele, {
        dpi: 300,
        // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
        useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
    }).then((canvas) => {

        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = contentWidth / 592.28 * 841.89;
        //未生成pdf的html页面高度
        var leftHeight = contentHeight;
        //页面偏移
        var position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28;
        var imgHeight = 595.28 / contentWidth * contentHeight;

        var pageData = canvas.toDataURL('image/jpeg', 1.0);



        var pdf = new JsPDF('', 'pt', 'a4');

        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
            //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
        } else { // 分页
            while (leftHeight > 0) {
                pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                leftHeight -= pageHeight;
                position -= 841.89;
                //避免添加空白页
                if (leftHeight > 0) {
                    pdf.addPage();
                }
            }
        }

        //可动态生成
        pdf.save(pdfName);
    })


}

function printOut(DomName) {
    //title，随意设置，也可以提出来做参数，传入进来，自己发挥
    var title = '测试啊';
    // var that = this;
    var shareContent = DomName; //需要截图的包裹的（原生的）DOM 对象
    //打印看有没有获取到dom
    var width = shareContent.offsetWidth; //获取dom 宽度
    var height = shareContent.offsetHeight; //获取dom 高度
    var canvas = document.createElement("canvas"); //创建一个canvas节点
    var scale = 2; //定义任意放大倍数 支持小数
    canvas.width = width * scale; //定义canvas 宽度 * 缩放，在此我是把canvas放大了2倍
    canvas.height = height * scale; //定义canvas高度 *缩放
    canvas.getContext("2d").scale(scale, scale); //获取context,设置scale
    html2canvas(DomName, {
        //允许跨域图片的加载
        useCORS: true,
    }).then(function (canvas) {
        var context = canvas.getContext("2d");
        // 【重要】关闭抗锯齿
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        var imgData = canvas.toDataURL('image/', 1.0); //转化成base64格式,可上网了解此格式
        var img = new Image();
        img.src = imgData;
        img.onload = function () {
            img.width = img.width / 2; //因为在上面放大了2倍，生成image之后要/2
            img.height = img.height / 2;
            img.style.transform = "scale(0.5)";
            var doc;
            if (this.width > this.height) {
                //此可以根据打印的大小进行自动调节
                doc = new JsPDF("l", "mm", [
                    this.width * 0.555,
                    this.height * 0.555
                ]);
            } else {
                doc = new JsPDF("p", "mm", [
                    this.width * 0.555,
                    this.height * 0.555
                ]);
            }
            doc.addImage(
                imgData,
                "jpeg",
                10,
                0,
                this.width * 0.505,
                this.height * 0.545
            );
            doc.save(title + "-文件.pdf");
        };
    });
}


const ws_pdf = function(elName, fileName) {
    html2canvas(elName, {

        scale: 2, // 提升画面质量，但是会增加文件大小
        
        }).then(function (canvas) {
        
        const contentWidth = canvas.width;
        
        const contentHeight = canvas.height;
        
        //一页pdf显示html页面生成的canvas高度;
        
        var pageHeight = (contentWidth / 592.28) * 841.89;
        
        //未生成pdf的html页面高度
        
        let leftHeight = contentHeight;
        
        //页面偏移
        
        let position = 0;
        
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        
        const imgWidth = 595.28;
        
        const imgHeight = (592.28 / contentWidth) * contentHeight;
        
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        
        const pdf = new JsPDF('', 'pt', 'a4');
        
        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        
        //当内容未超过pdf一页显示的范围，无需分页
        
        if (leftHeight < pageHeight) {
        
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        
        } else {
        
        while (leftHeight > 0) {
        
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        
        leftHeight -= pageHeight;
        
        position -= 841.89;
        
        //避免添加空白页
        
        if (leftHeight > 0) {
        
        pdf.addPage();
        
        }
        
        }
        
        }
        
        // pdf.save(`${sessionStorage.getItem('name')}.pdf`);
        pdf.save(`${fileName}.pdf`)
        
        });
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         try {
    //             const scale = 2
    //             // 滚动到顶部，避免打印不全
    //             document.documentElement.scrollTop = 0

    //             html2canvas(document.querySelector(elName), {
    //                 // allowTaint: true, // Whether to allow cross-origin images to taint the canvas
    //                 scale, // The scale to use for rendering. Defaults to the browsers device pixel ratio.
    //                 useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
    //             }).then(canvas => {
    //                 const contentWidth = canvas.width / scale
    //                 const contentHeight = canvas.height / scale
    //                 const pdf = new JsPDF('', 'pt', [contentWidth, contentHeight])
    //                 const pageData = canvas.toDataURL('image/jpeg', 1.0)

    //                 pdf.addImage(pageData, 'JPEG', 0, 0, contentWidth, contentHeight)
    //                 pdf.save(`${fileName}.pdf`)
    //                 resolve(fileName)
    //             })
    //         } catch (err) {
    //             reject(err)
    //         }
    //     }, 1000)
    // })
}

export default {
    downloadPDF,
    printOut,
    ws_pdf
}